<template>
  <div class="ixu-nav-menu">
    <div
      v-for="(item, index) in list"
      :class="{
        'ixu-nav-menu__active': currentIndex === index,
        'ixu-nav-menu__corner-mark': item[props.cornerMarkNum] != null
      }"
      @click="click(item, index)"
      :key="item[props.value]"
    >
      {{ item[props.label] }}
      <sup v-if="item[props.cornerMarkNum] != null" class="ixu-nav-menu__badge">
        {{
          item[props.cornerMarkNum] > 999 ? "999+" : item[props.cornerMarkNum]
        }}
      </sup>
    </div>
  </div>
</template>

<script>
export default {
  name: "ixu-nav-menu",
  data() {
    return {
      currentIndex: this.defaultActive
    };
  },
  props: {
    list: {
      type: Array,
      default: function() {
        return [];
      },
      required: true
    },
    defaultActive: {
      type: Number,
      required: true
    },
    props: {
      type: Object,
      default: () => {
        return {
          value: "value",
          label: "label",
          cornerMarkNum: "cornerMarkNum"
        };
      },
      required: false
    }
  },
  methods: {
    click(item, index) {
      this.currentIndex = index;
      this.$emit("click", item, index);
    }
  }
};
</script>
<style lang="scss" scoped>
.ixu-nav-menu {
  height: 32px;
  display: inline-block;
  div {
    display: inline-block;
    position: relative;
    border: 0;
    line-height: 1;
    white-space: nowrap;
    background: #fff;
    padding: 8px 8px 8px 8px;
    color: #606266;
    outline: none !important;
    cursor: pointer;
    &:before {
      content: "";
      position: absolute;
      left: 50%;
      bottom: 16px;
      height: 2px;
      background-color: #20a0ff;
      width: 30px;
      margin-left: -15px;
      transition: all 0.3s;
      transform: translate3d(0, 11px, 0);
      opacity: 0;
    }
    &:hover {
      &:before {
        opacity: 1;
        bottom: 0;
      }
    }
    &.ixu-nav-menu__active {
      color: #409eff;
      font-size: 18px;
      &:before {
        transform: translate3d(0, 11px, 0);
        opacity: 1;
        bottom: 0;
      }
    }
    &.ixu-nav-menu__corner-mark {
      margin-right: 40px;
    }
    .ixu-nav-menu__badge {
      position: absolute;
      right: 5px;
      transform: translateY(-50%) translateX(100%);
      background-color: #f56c6c;
      border-radius: 10px;
      color: #fff;
      display: inline-block;
      font-size: 12px;
      height: 18px;
      line-height: 18px;
      padding: 0 6px;
      text-align: center;
      white-space: nowrap;
      border: 1px solid #fff;
    }
  }
}
</style>
