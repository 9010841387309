export const FILE_MODULE = "customWebsite";

export const FILE_EXT_TYPE = {
  iso: [
    "iso",
    "cue",
    "bin",
    "cif",
    "fcd",
    "tao",
    "dao",
    "dfi",
    "img",
    "ccd",
    "mgd",
    "mdf",
    "mds",
    "cdi",
    "nrg",
    "vcd"
  ],
  mp4: ["avi", "flv", "m4v", "mkv", "mov", "mp4", "mpeg", "mpg", "wmv", "swf"],
  mp3: ["m3u", "mp2", "mp3", "wma"],
  xls: ["xls", "xlsx"],
  doc: ["doc", "docx"],
  png: ["gif", "jpeg", "jpg", "png", "bmp", "tiff", "heic"],
  pdf: ["pdf"],
  ppt: ["ppt"],
  psd: ["psd"],
  ai: ["ai"],
  txt: ["txt", "md"],
  zip: ["7z", "deb", "dmg", "gz", "rar", "zip"],
  web: [
    "html",
    "xhtml",
    "xml",
    "vue",
    "js",
    "ts",
    "json",
    "php",
    "asp",
    "jsp",
    "aspx"
  ],
  css: ["css", "sass", "scss", "less"],
  win: ["exe", "dll"],
  ttf: ["ttf", "otf", "ttc"]
};

export const FILE_ACTIVE_TYPE = {
  1: "文件",
  3: "图片",
  4: "视频"
};

export function hasOwnProperty(obj, prop) {
  return Object.prototype.hasOwnProperty.call(obj, prop);
}

// 判断文件格式
export function getExtType(name) {
  // console.log('pan::file::getExtType', name)
  // 返回文件扩展名
  let arr = name.split(".");
  let ext = arr[arr.length - 1].toLowerCase();
  let type = "none";
  for (let key in FILE_EXT_TYPE) {
    if (hasOwnProperty(FILE_EXT_TYPE, key)) {
      let item = FILE_EXT_TYPE[key];
      let index = item.indexOf(ext);
      if (index !== -1) {
        type = key;
      }
    }
  }
  return {
    ext,
    type
  };
}

//格式化流量,根据流量大小格式化到不同单位,保留两位小数
export function bytesToSize(bytes) {
  if (!!bytes === 0) return "0 B";
  let k = 1024; // or 1000
  let sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  let i = Math.floor(Math.log(bytes) / Math.log(k));
  return (bytes / Math.pow(k, i)).toPrecision(3) + " " + sizes[i];
}
