<template>
  <div class="ixu-upload">
    <el-upload
      class="ixu-upload--el"
      :action="uploadAction"
      :on-preview="onPreview"
      :on-remove="handleRemove"
      :on-change="handleUploadChange"
      :on-success="handleUploadSuccess"
      :on-exceed="handleExceed"
      :showFileList="showFileList"
      :before-upload="beforeUpload"
      :name="name"
      :accept="accept"
      :disabled="disabled"
      :multiple="multiple"
      :limit="limit"
      :list-type="listType"
      :file-list="uploadFiles"
    >
      <slot>
        <el-button size="medium" type="ghost">{{ btnText }} </el-button>
      </slot>
    </el-upload>
    <div class="opacity-5 fs-12 b-t" v-if="tip">{{ tip }}</div>
  </div>
</template>

<script>
import lrz from "lrz";
import cloneDeep from "lodash/cloneDeep";
import isArray from "lodash/isArray";
import has from "lodash/has";
import Vue from "vue";
import { Upload } from "element-ui";

Vue.use(Upload);

export default {
  name: "IxuUpload",
  props: {
    action: {
      type: String
    },
    fileList: {
      type: Array,
      default() {
        return [];
      }
    },
    autoUpload: {
      type: Boolean,
      default: true
    },
    showFileList: {
      type: Boolean,
      default: true
    },
    listType: {
      type: String,
      default: "text" // text,picture,picture-card
    },
    multiple: {
      type: Boolean,
      default: true
    },
    accept: String,
    name: {
      type: String,
      default: "fileList"
    },
    data: Object,
    disabled: Boolean,
    limit: {
      type: Number,
      default: 9 // text,picture,picture-card
    },
    btnText: {
      type: String,
      default: "点击上传" // text,picture,picture-card
    },
    tip: {
      type: String,
      default:
        "附件最大20M，支持格式：PDF、Word、Excel、Txt、JPG、PNG、BMP、GIF、RAR、ZIP"
    },
    lrz: {
      type: Boolean,
      default: false
    },
    onPreview: {
      type: Function
    }
  },
  data() {
    return {
      uploadFiles: []
    };
  },
  computed: {
    uploadAction() {
      if (this.action) return this.action;
      return `/bdsaasfile/ajax/common/uploadFile2.do?token=${this.$store.state.token}&COMPANYID=${this.companyId}`;
    }
  },
  watch: {
    fileList: {
      immediate: true,
      deep: true,
      handler(fileList) {
        this.uploadFiles = fileList.map(item => {
          // item.uid = item.uid || (Date.now() + this.tempIndex++);
          item.status = item.status || "success";
          if (has(item, "response")) {
            item = item.response.data[0];
            if (this.listType === "picture-card") {
              item.url = this.getAvatarLink(item.fileId);
            }
          }
          return item;
        });
      }
    }
  },
  methods: {
    beforeUpload(file) {
      // var that = this;
      return new Promise((resolve, reject) => {
        const isLt2M = file.size / 1024 / 1024 > 300;
        if (isLt2M) {
          this.$message.error("文件大小不能超过 300MB!");
          reject(new Error("文件大小不能超过 300MB!"));
          return;
        }
        console.log("beforeUpload file", file);
        if (
          this.lrz &&
          [
            "image/jpg",
            "image/jpeg",
            "image/png",
            "image/pjpeg",
            "image/gif",
            "image/bmp",
            "image/x-png"
          ].includes(file.type)
        ) {
          lrz(file, {
            width: 1920,
            quality: 0.8,
            fieldName: this.name
          })
            .then(function(rst) {
              console.log("beforeUpload lrz", rst);
              // 把处理的好的图片给用户看看呗
              resolve(rst.file); //file
            })
            .catch(function(err) {
              // 万一出错了，这里可以捕捉到错误信息
              // 而且以上的then都不会执行
              alert(err);
            })
            .always(function() {
              // 不管是成功失败，这里都会执行
            });
        } else {
          resolve(file); //file
        }
      });
    },
    // 上传附件-------------------------------------
    handleRemove(file, fileList) {
      this.handFileList(cloneDeep(fileList));
    },
    handleUploadChange(file, fileList) {
      console.log("handleUploadChange", fileList);
      // fix 图片上传不需要change
      if (this.listType === "picture-card") return;
      this.handFileList(fileList);
    },
    handleUploadSuccess(response, file, fileList) {
      console.log("handleUploadSuccess", fileList);
      file = {
        ...file,
        ...file.response.data[0]
      };
      this.$emit("update:file", file);
      this.handFileList(fileList);
    },
    // 处理附件id
    handFileList(fileList = []) {
      // handFileList: _.throttle(function(fileList = []) {
      console.log("upload>handFileList", fileList);
      if (!isArray(fileList)) {
        return;
      }
      fileList = fileList.map(item => {
        if (has(item, "response")) {
          item = item.response.data[0];
          if (this.listType === "picture-card") {
            item.url = this.getAvatarLink(item.fileId);
          }
        }
        return item;
      });
      console.log("upload>handFileList", fileList);
      // this.uploadFiles = fileList;
      this.$emit("update:fileList", fileList);
    },
    // }, 500),
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 ${this.limit} 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      );
    }
  }
};
</script>

<style lang="scss" scoped></style>
