<template>
  <div class="ixu-none">
    <slot>
      <i
        class="ixu-icon-jianchagongzuofenxi text-muted"
        style="font-size:80px;margin-bottom:20px"
      ></i>
      <div class="ixu-none-txt">{{ tip || "暂无数据" }}</div>
    </slot>
  </div>
</template>

<script>
export default {
  name: "ixu-none",
  props: {
    tip: {
      type: String,
      default: "暂无数据"
    }
  },
  computed: {},
  methods: {},
  created() {}
};
</script>

<style lang="scss">
.ixu-none-wrap {
  display: table;
  width: 100%;
}

.ixu-none {
  display: table-cell;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;

  & > i {
    font-size: 80px;
    opacity: 0.6;
  }

  .econ {
    max-width: 80px;
    margin: 0 auto;
  }

  .ixu-none-txt {
    margin-top: 16px;
    font-size: 20px;
    color: #79818b;
  }

  .ixu-none-btn {
    margin-top: 30px;
  }
}

.ixu-none .ixu-none-txt {
  margin-top: 0 !important;
  margin-bottom: 15px;
}

.ixu-none > i {
  margin-top: -20px;
}
</style>
