<template>
  <div class="ztree_v3" :id="ztreeId"></div>
</template>

<script>
import * as $ from "jquery";

if (!window.jQuery) {
  window.jQuery = $;
}
require("@ztree/ztree_v3/js/jquery.ztree.all");
export default {
  name: "ixu-tree",
  props: {
    setting: {
      type: Object,
      require: false,
      default: function() {
        return {};
      }
    },
    beforeCheck: {
      type: Function,
      require: false
    },
    nodes: {
      type: Array,
      require: true,
      default: function() {
        return [];
      }
    }
  },
  data() {
    return {
      ztreeId: "ztree_" + parseInt(Math.random() * 1e10),
      ztreeObj: null,
      list: [],
      ztreeSetting: {
        view: {
          showIcon: false
        },
        callback: {
          beforeCheck: this.beforeCheck,
          onAsyncError: (...arg) => {
            this.$emit("onAsyncError", ...arg);
          },
          onAsyncSuccess: (...arg) => {
            this.$emit("onAsyncSuccess", ...arg);
          },
          onCheck: (...arg) => {
            this.$emit("onCheck", ...arg);
          },
          onClick: (...arg) => {
            this.$emit("onClick", ...arg);
          },
          onCollapse: (...arg) => {
            this.$emit("onCollapse", ...arg);
          },
          onDblClick: (...arg) => {
            this.$emit("onDblClick", ...arg);
          },
          onDrag: (...arg) => {
            this.$emit("onDrag", ...arg);
          },
          onDragMove: (...arg) => {
            this.$emit("onDragMove", ...arg);
          },
          onDrop: (...arg) => {
            this.$emit("onDrop", ...arg);
          },
          onExpand: (...arg) => {
            this.$emit("onExpand", ...arg);
          },
          onMouseDown: (...arg) => {
            this.$emit("onMouseDown", ...arg);
          },
          onMouseUp: (...arg) => {
            this.$emit("onMouseUp", ...arg);
          },
          onRemove: (...arg) => {
            this.$emit("onRemove", ...arg);
          },
          onRename: (...arg) => {
            this.$emit("onRename", ...arg);
          },
          onRightClick: (...arg) => {
            this.$emit("onRightClick", ...arg);
          }
        }
      }
    };
  },
  watch: {
    nodes: {
      handler: function(nodes) {
        this.list = nodes;
        // update tree
        if (this.ztreeObj) {
          this.ztreeObj.destroy();
        }
        this.$nextTick(() => {
          this.ztreeObj = $.fn.zTree.init(
            $("#" + this.ztreeId),
            Object.assign({}, this.ztreeSetting, this.setting),
            this.list
          );
          this.$emit("onCreated", this.ztreeObj);
        });
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style scoped>
/* beauty ztree! */
.ztree_v3 {
  text-align: left;
  font-size: 14px;
}

.ztree_v3 >>> li {
  list-style-type: none;
  white-space: nowrap;
  outline: none;
}

.ztree_v3 >>> li ul {
  position: relative;
  padding: 0 0 0 20px;
  margin: 0;
}

.ztree_v3 >>> .line:before {
  position: absolute;
  top: 0;
  left: 10px;
  height: 100%;
  content: "";
  border-right: 1px dotted #dbdbdb;
}

.ztree_v3 >>> .roots_docu:before,
.ztree_v3 >>> .roots_docu:after,
.ztree_v3 >>> .center_docu:before,
.ztree_v3 >>> .bottom_docu:before,
.ztree_v3 >>> .center_docu:after,
.ztree_v3 >>> .bottom_docu:after {
  position: absolute;
  content: "";
  border: 0 dotted #dbdbdb;
}

.ztree_v3 >>> .roots_docu:before {
  left: 10px;
  height: 50%;
  top: 50%;
  border-left-width: 1px;
}

.ztree_v3 >>> .roots_docu:after {
  top: 50%;
  left: 11px;
  width: 50%;
  border-top-width: 1px;
}

.ztree_v3 >>> .center_docu:before {
  left: 10px;
  height: 100%;
  border-left-width: 1px;
}

.ztree_v3 >>> .center_docu:after {
  top: 50%;
  left: 11px;
  width: 50%;
  border-top-width: 1px;
}

.ztree_v3 >>> .bottom_docu:before {
  left: 10px;
  height: 50%;
  border-left-width: 1px;
}

.ztree_v3 >>> .bottom_docu:after {
  top: 50%;
  left: 11px;
  width: 50%;
  border-top-width: 1px;
}

.ztree_v3 >>> li a {
  display: inline-block;
  line-height: 32px;
  width: 320px;
  height: 32px;
  margin: 0;
  cursor: pointer;
  transition: none;
  vertical-align: middle;
  color: #555555;
}

.ztree_v3 >>> .node_name {
  display: inline-block;
  padding: 0 3px;
  border-radius: 4px;
}

.ztree_v3 >>> .curSelectedNode {
  background-color: #f5f7fa;
}

.ztree_v3 >>> .curSelectedNode .node_name {
  /*color: #000;*/
  /*background-color: #c9e9f7;*/
}

.ztree_v3 >>> .curSelectedNode_Edit {
  height: 20px;
  opacity: 0.8;
  color: #000;
  border: 1px #6cc2e8 solid;
  background-color: #9dd6f0;
}

.ztree_v3 >>> .tmpTargetNode_inner {
  opacity: 0.8;
  color: #fff;
  background-color: #4fcbf0;
  filter: alpha(opacity=80);
}

.ztree_v3 >>> .rename {
  font-size: 12px;
  line-height: 32px;
  width: 80px;
  height: 32px;
  margin: 0;
  padding: 0;
  vertical-align: top;
  border: 0;
  background: none;
}

.ztree_v3 >>> .button {
  position: relative;
  display: inline-block;
  line-height: 32px;
  height: 32px;
  width: 24px;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
}

.ztree_v3 >>> .button.edit {
  color: #25ae88;
}

.ztree_v3 >>> .button.remove {
  color: #f56c6c;
  font-size: 20px;
  float: right;
  font-family: element-icons !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  vertical-align: baseline;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 32px;
}
.ztree_v3 >>> .button.remove:before {
  content: "\e6db";
}

.ztree_v3 >>> .button.chk {
  position: relative;
  width: 16px;
  height: 16px;
  margin: 0 4px 0 0;
  border: 1px solid #d7dde4;
  border-radius: 2px;
  background: #fff;
}

.ztree_v3 >>> .chk.radio_true_full,
.ztree_v3 >>> .chk.radio_false_full,
.ztree_v3 >>> .chk.radio_true_full_focus,
.ztree_v3 >>> .chk.radio_false_full_focus,
.ztree_v3 >>> .chk.radio_false_disable,
.ztree_v3 >>> .chk.radio_true_disable,
.ztree_v3 >>> .chk.radio_true_part,
.ztree_v3 >>> .chk.radio_false_part,
.ztree_v3 >>> .chk.radio_true_part_focus,
.ztree_v3 >>> .chk.radio_false_part_focus {
  border-radius: 8px;
}

.ztree_v3 >>> .button.chk:after {
  position: absolute;
  top: 2px;
  left: 5px;
  width: 4px;
  height: 8px;
  content: "";
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  -webkit-transform: rotate(0deg) scale(0);
  transform: rotate(0deg) scale(0);
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
}

.ztree_v3 >>> .button.checkbox_false_full_focus {
  border-color: #ccc;
}

.ztree_v3 >>> .button.checkbox_true_full,
.ztree_v3 >>> .button.checkbox_true_full_focus,
.ztree_v3 >>> .button.checkbox_true_part,
.ztree_v3 >>> .button.checkbox_true_part_focus,
.ztree_v3 >>> .button.checkbox_true_disable {
  border-color: #39f;
  background-color: #39f;
}

.ztree_v3 >>> .button.checkbox_true_full:after,
.ztree_v3 >>> .button.checkbox_true_full_focus:after,
.ztree_v3 >>> .button.checkbox_true_disable:after {
  -webkit-transform: rotate(45deg) scale(1);
  transform: rotate(45deg) scale(1);
}

.ztree_v3 >>> .button.checkbox_true_part:after,
.ztree_v3 >>> .button.checkbox_true_part_focus:after {
  top: 5px;
  left: 2px;
  width: 10px;
  height: 1px;
  -webkit-transform: rotate(0deg) scale(1);
  transform: rotate(0deg) scale(1);
  border-right: 0;
}

.ztree_v3 >>> .button.radio_true_full,
.ztree_v3 >>> .chk.radio_true_full_focus,
.ztree_v3 >>> .chk.radio_true_part,
.ztree_v3 >>> .chk.radio_true_part_focus {
  border-color: #39f;
}

.ztree_v3 >>> .button.radio_true_full:after,
.ztree_v3 >>> .chk.radio_true_full_focus:after,
.ztree_v3 >>> .chk.radio_true_part:after,
.ztree_v3 >>> .chk.radio_true_part_focus:after {
  top: 3px;
  left: 3px;
  width: 8px;
  -webkit-transform: rotate(0deg) scale(1);
  transform: rotate(0deg) scale(1);
  border: 0;
  border-radius: 4px;
  background: #39f;
}

.ztree_v3 >>> .button.checkbox_true_disable,
.ztree_v3 >>> .button.checkbox_false_disable,
.ztree_v3 >>> .chk.radio_false_disable,
.ztree_v3 >>> .chk.radio_true_disable {
  cursor: not-allowed;
}

.ztree_v3 >>> .button.checkbox_false_disable {
  background-color: #f3f3f3;
}

.ztree_v3 >>> .button.noline_close:before,
.ztree_v3 >>> .button.noline_open:before,
.ztree_v3 >>> .button.root_open:before,
.ztree_v3 >>> .button.root_close:before,
.ztree_v3 >>> .button.roots_open:before,
.ztree_v3 >>> .button.roots_close:before,
.ztree_v3 >>> .button.bottom_open:before,
.ztree_v3 >>> .button.bottom_close:before,
.ztree_v3 >>> .button.center_open:before,
.ztree_v3 >>> .button.center_close:before {
  position: absolute;
  top: 5px;
  left: 5px;
  content: "";
  transition: -webkit-transform ease 0.3s;
  transition: transform ease 0.3s;
  transition: transform ease 0.3s, -webkit-transform ease 0.3s;
  -webkit-transform: rotateZ(0deg);
  transform: rotateZ(0deg);
  -webkit-transform-origin: 25% 50%;
  transform-origin: 25% 50%;
  border: 6px solid;
  border-color: transparent transparent transparent #666;
}

.ztree_v3 >>> .button.noline_open:before,
.ztree_v3 >>> .button.root_open:before,
.ztree_v3 >>> .button.roots_open:before,
.ztree_v3 >>> .button.bottom_open:before,
.ztree_v3 >>> .button.center_open:before {
  -webkit-transform: rotateZ(90deg);
  transform: rotateZ(90deg);
}

.ztree_v3 >>> .button.ico_loading {
  margin-right: 2px;
  background: url("data:image/gif;base64,R0lGODlhEAAQAKIGAMLY8YSx5HOm4Mjc88/g9Ofw+v///wAAACH/C05FVFNDQVBFMi4wAwEAAAAh+QQFCgAGACwAAAAAEAAQAAADMGi6RbUwGjKIXCAA016PgRBElAVlG/RdLOO0X9nK61W39qvqiwz5Ls/rRqrggsdkAgAh+QQFCgAGACwCAAAABwAFAAADD2hqELAmiFBIYY4MAutdCQAh+QQFCgAGACwGAAAABwAFAAADD1hU1kaDOKMYCGAGEeYFCQAh+QQFCgAGACwKAAIABQAHAAADEFhUZjSkKdZqBQG0IELDQAIAIfkEBQoABgAsCgAGAAUABwAAAxBoVlRKgyjmlAIBqCDCzUoCACH5BAUKAAYALAYACgAHAAUAAAMPaGpFtYYMAgJgLogA610JACH5BAUKAAYALAIACgAHAAUAAAMPCAHWFiI4o1ghZZJB5i0JACH5BAUKAAYALAAABgAFAAcAAAMQCAFmIaEp1motpDQySMNFAgA7")
    0 center no-repeat;
}

.ztree_v3 >>> .tmpTargetzTree {
  opacity: 0.8;
  background-color: #2ea9df;
  filter: alpha(opacity=80);
}

.ztree_v3 >>> .tmpzTreeMove_arrow {
  position: absolute;
  width: 18px;
  height: 18px;
  color: #4fcbf0;
}

.ztree_v3 >>> .ztree_v3.zTreeDragUL {
  overflow: hidden;
  position: absolute;
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  opacity: 0.8;
  border: 1px #176b53 dotted;
  background-color: #dbdbdb;
  filter: alpha(opacity=80);
}

.zTreeMask {
  position: absolute;
  z-index: 10000;
  opacity: 0;
  background-color: #cfcfcf;
  filter: alpha(opacity=0);
}
</style>

<style lang="scss">
ul.ztree.zTreeDragUL {
  margin: 0;
  padding: 0;
  position: absolute;
  overflow: hidden;
  background-color: #dedede;
  border: 1px #4fcbf0 dotted;
  border-radius: 4px;
  opacity: 0.7;
}
.zTreeMask {
  position: absolute;
  z-index: 10000;
  opacity: 0;
  background-color: #cfcfcf;
}
</style>
