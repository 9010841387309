<template>
  <el-drawer
    :show-close="false"
    :visible.sync="show"
    :direction="direction"
    :modal="modal"
    :size="size"
    @opened="opened"
    @closed="closed"
    @close="close"
    :destroy-on-close="destroyOnClose"
    :withHeader="false"
    :modal-append-to-body="true"
    :close-on-press-escape="false"
    :append-to-body="transfer"
    :wrapperClosable="closeOnClickOverlay"
  >
    <div class="ixu-drawer">
      <div class="ixu-drawer-hd" v-show="withHeader">
        <slot name="header">
          <el-row>
            <el-col :span="20">
              <span class="text-ellipsis" v-text="title"></span>
            </el-col>
            <el-col :span="4">
              <a
                href="javascript:;"
                @click="close"
                class="ixu-drawer-close pull-right"
              >
                <i class="el-icon-close fs-18-b"></i>
              </a>
            </el-col>
          </el-row>
        </slot>
      </div>
      <div class="ixu-drawer-ft" v-if="hasFooter">
        <slot name="footer"></slot>
      </div>
      <div class="ixu-drawer-bd" v-loading="showDomLoading">
        <div class="ixu-drawer-bd-inner" v-if="showDom">
          <slot></slot>
        </div>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import Vue from "vue";
import { Drawer } from "element-ui";

Vue.use(Drawer);
export default {
  name: "ixu-drawer",
  components: {},
  directives: {},
  props: {
    // 是否显示 Drawer，支持 .sync 修饰符
    open: {
      type: Boolean,
      default: false
    },
    // 控制是否显示 header 栏, 默认为 true,
    withHeader: {
      type: Boolean,
      default: true
    },
    // Drawer 打开的方向 rtl / ltr / ttb / btt
    direction: {
      type: String,
      default: "rtl"
    },
    // 是否需要遮罩层
    modal: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: "我是标题"
    },
    // Drawer 窗体的大小, 当使用 number 类型时, 以像素为单位, 当使用 string 类型时, 请传入 'x%', 否则便会以 number 类型解释
    width: {
      type: [Number, String]
    },
    // 控制是否在关闭 Drawer 之后将子元素全部销毁
    destroyOnClose: {
      type: Boolean,
      default: true
    },
    // 插入至 body 元素上
    transfer: {
      type: Boolean,
      default: true
    },
    // 点击遮罩层是否可以关闭 Drawer
    closeOnClickOverlay: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      show: false,
      showDom: false,
      showDomLoading: false
    };
  },
  watch: {
    open(val) {
      this.show = val;
    },
    show(val) {
      if (val) {
        this.showDomLoading = true;
        setTimeout(() => {
          this.showDom = true;
          this.showDomLoading = false;
        }, 350);
        return;
      }
      this.showDom = false;
      this.showDomLoading = false;
      this.$emit("update:open", val);
    }
  },
  computed: {
    size() {
      return typeof this.width === "number" ? this.width + "px" : this.width;
    },
    hasFooter() {
      return !!this.$slots.footer;
    }
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    close() {
      this.show = false;
      this.$emit("close");
    },
    closed() {
      this.$emit("closed");
    },
    opened() {
      this.$emit("opened");
    }
  }
};
</script>

<style lang="scss">
//* {
//  box-sizing: border-box;
//  outline: none;
//  &:focus,
//  &:active {
//    outline: none;
//  }
//}
.v-modal {
  background-color: rgba(55, 55, 55, 0.5);
}
.el-drawer.rtl {
  outline: none;
}
.ixu-drawer {
  position: relative;
  overflow: auto;
  height: 100vh;
  outline: none;

  .ixu-drawer-hd {
    border-bottom: 1px solid #f0f0f0;
    position: absolute;
    font-size: 18px;
    height: 60px;
    line-height: 32px;
    padding: 14px 15px;
    width: 100%;
    left: 0;
    top: 0;
    right: auto;
    z-index: 1;
    box-sizing: border-box;

    .ixu-drawer-close {
      display: inline-block;
      width: 34px;
      height: 34px;
      text-align: center;
      line-height: 34px;
      color: #000000;
    }
  }

  .ixu-drawer-bd {
    height: 100%;
    padding-top: 60px;
    position: relative;
    box-sizing: border-box;
  }

  .ixu-drawer-bd-inner {
    height: 100%;
    position: relative;
    overflow-y: auto;

    .ixu-drawer-bd-header {
      position: absolute;
      font-size: 14px;
      height: 52px;
      line-height: 32px;
      padding: 10px 15px;
      left: 0;
      top: 0;
      right: 0;
      z-index: 0;
      font-size: 14px;
      background: #ffffff;

      i {
        vertical-align: -2px;
      }
    }

    .ixu-drawer-bd-header + .ixu-drawer-bd-body {
      padding-top: 60px;
    }
  }

  .ixu-drawer-ft {
    box-sizing: border-box;
    & + .ixu-drawer-bd {
      padding-bottom: 60px;
    }

    width: 100%;
    position: absolute;
    right: 0;
    bottom: 0;
    left: auto;
    background-color: #fff;
    height: 56px;
    line-height: 36px;
    padding: 10px 16px;
    z-index: 1;
  }
}

.ixu-drawer::-webkit-scrollbar {
  display: none !important;
  width: 0 !important;
  height: 0 !important;
  -webkit-appearance: none;
  opacity: 0 !important;
}
</style>
