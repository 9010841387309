<template>
  <el-color-picker
    :value="value"
    :size="size"
    show-alpha
    :predefine="localColors"
    @change="colorChanged"
  >
  </el-color-picker>
</template>

<script>
import storage from "../../../src/utils/storage";
const defaultColors = [
  "#ffffff",
  "#f4f4f4",
  "#c0c0c0",
  "#888888",
  "#222222",
  "#FEF0F0",
  "#FF5436",
  "#F03A58",
  "#00A5FF",
  "#00C65D"
];

export default {
  name: "ixu-color-picker",
  data() {
    let localColors = storage.get("pickedColors");
    return {
      localColors: defaultColors.concat(localColors || [])
    };
  },
  props: {
    value: String,
    size: String
  },
  methods: {
    colorChanged(value) {
      value = value ? value.replace(/\s/g, "") : value;
      this.$emit("input", value);
      this.$emit("change", value);
      this.saveLocalColor(value);
    },
    saveLocalColor(color) {
      if (!color) return;
      let localColors = storage.get("pickedColors") || [];
      let index = localColors.indexOf(color);
      if (index > -1) {
        localColors.splice(index, 1);
      }
      localColors.unshift(color);
      if (localColors.length > 20) {
        localColors.length = 20;
      }
      storage.set("pickedColors", localColors);
      this.localColors = defaultColors.concat(localColors);
    }
  }
};
</script>

<style lang="scss"></style>
