import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/Home.vue"),
    children: [
      {
        path: "/",
        name: "home-start",
        component: () => import("../docs/quickstart.md")
      },
      {
        path: "/changelog",
        name: "changelog",
        component: () => import("../../CHANGELOG.md")
      },
      {
        path: "/guide/install",
        name: "install",
        component: () => import("../docs/install.md")
      },
      {
        path: "/guide/backend",
        name: "guide-backend",
        component: () => import("../docs/guide-backend.md")
      },
      {
        path: "/guide/fe",
        name: "guide-fe",
        component: () => import("../docs/guide-fe.md")
      },
      {
        path: "/component/page-template",
        name: "page-template",
        component: () => import("../docs/page-template.md")
      },
      {
        path: "/component/context-menu",
        name: "context-menu",
        component: () => import("../docs/context-menu.md")
      },
      {
        path: "/component/qrcode",
        name: "qrcode",
        component: () => import("../docs/qrcode.md")
      },
      {
        path: "/component/icon",
        name: "icon",
        component: () => import("../docs/icon.md")
      },
      {
        path: "/component/container",
        name: "container",
        component: () => import("../docs/container.md")
      },
      {
        path: "/component/nav-menu",
        name: "nav-menu",
        component: () => import("../docs/nav-menu.md")
      },
      {
        path: "/component/user-dep-role",
        name: "user-dep-role",
        component: () => import("../docs/user-dep-role.md")
      },
      {
        path: "/component/upload",
        name: "upload",
        component: () => import("../docs/upload.md")
      },
      {
        path: "/component/tree",
        name: "tree",
        component: () => import("../docs/tree.md")
      },
      {
        path: "/component/editor",
        name: "editor",
        component: () => import("../docs/editor.md")
      },
      {
        path: "/component/draggable",
        name: "draggable",
        component: () => import("../docs/draggable.md")
      },
      {
        path: "/plugin/console",
        name: "console",
        component: () => import("../docs/console.md")
      },
      {
        path: "/component/pan",
        name: "pan",
        component: () => import("../docs/pan.md")
      },
      {
        path: "/component/color-picker",
        name: "color-picker",
        component: () => import("../docs/color-picker.md")
      },
      {
        path: "/component/pagination",
        name: "pagination",
        component: () => import("../docs/pagination.md")
      },
      {
        path: "/component/drawer",
        name: "drawer",
        component: () => import("../docs/drawer.md")
      }
    ]
  }
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.NODE_ENV !== "production" ? "/" : "/",
  routes
});

export default router;
