import { version } from "../package.json";
import "./theme/index.scss";
// import storage from "./utils/storage";
import log from "./utils/log";

const components = [];

const importAll = require.context("../packages", true, /index\.js$/);

const install = function(Vue, opts = {}) {
  importAll.keys().map(filepath => {
    const component = importAll(filepath).default || importAll(filepath);
    Vue.component(component.name, component);
    components.push(component.name);
  });

  Vue.prototype.$ELEMENT = {
    size: opts.size || "",
    zIndex: opts.zIndex || 2000
  };

  // Vue.prototype.$storage = storage;
  window.$log = Vue.prototype.$log = log;
};

if (typeof window !== "undefined" && window.Vue) {
  install(window.Vue);
}

export default {
  version: version,
  install,
  ...components
};
