<template>
  <div>
    <el-dialog
      :visible="visible"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      :append-to-body="true"
      :center="true"
      :show-close="false"
      custom-class="pan-list-dialog"
      width="940px"
    >
      <template slot="title">
        <div>我的云盘</div>
        <button class="el-dialog__headerbtn" @click="close">
          <i class="el-icon-close"></i>
        </button>
      </template>
      <!-- 组件编辑器 -->
      <div style="height: 570px;">
        <div
          class="pan-tabs el-tabs el-tabs--left el-tabs--border-card"
          style="height: 570px;"
        >
          <div class="el-tabs__header is-left">
            <div class="el-tabs__nav-wrap is-left">
              <div class="el-tabs__nav-scroll">
                <div
                  role="tablist"
                  class="el-tabs__nav is-left"
                  style="transform: translateY(0px);"
                >
                  <div
                    id="tab-0"
                    tabindex="0"
                    v-if="fileType.includes('file')"
                    :class="{ 'is-active': activeIndex === 0 }"
                    @click="activeIndex = 0"
                    class="el-tabs__item is-left "
                  >
                    我的文件
                  </div>
                  <div
                    id="tab-3"
                    tabindex="-1"
                    v-if="fileType.includes('img')"
                    :class="{ 'is-active': activeIndex === 3 }"
                    @click="activeIndex = 3"
                    class="el-tabs__item is-left"
                  >
                    我的图片
                  </div>
                  <div
                    id="tab-4"
                    tabindex="-1"
                    v-if="fileType.includes('video')"
                    :class="{ 'is-active': activeIndex === 4 }"
                    @click="activeIndex = 4"
                    class="el-tabs__item is-left"
                  >
                    我的视频
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="el-tabs__content" v-loading="loading">
            <div id="pane-0" class="el-tab-pane" style="height: 480px">
              <label
                class="el-checkbox el-checkbox-pan"
                v-for="(item, index) in listData"
                :key="item.fileId + '__' + index"
                :class="{ ' is-checked': item.fileId === checkFile.fileId }"
                @click="checkFile = item"
              >
                <span
                  class="el-checkbox__input"
                  :class="{ ' is-checked': item.fileId === checkFile.fileId }"
                >
                  <span class="el-checkbox__inner"></span>
                  <input
                    type="checkbox"
                    aria-hidden="false"
                    class="el-checkbox__original"
                    :checked="item.fileId === checkFile.fileId"
                    :value="item.fileId"
                /></span>

                <span class="el-checkbox__label">
                  <div
                    class="el-checkbox__image flex flex-cross-center flex-main-center"
                  >
                    <el-image
                      v-if="getExtTypeByName(item.displayName) === 'png'"
                      fit="contain"
                      style="width: 90px; height: 90px"
                      :alt="item.name"
                      :data-src="item.fileUrl"
                      :src="item.fileUrl"
                    >
                    </el-image>
                    <el-image
                      v-else
                      fit="cover"
                      style="width: 58px; height: 69px"
                      :alt="item.name"
                      :data-src="
                        `https://vip.bdsaas.com/static/img/file/${getExtTypeByName(
                          item.displayName
                        )}.png`
                      "
                      :src="
                        `https://vip.bdsaas.com/static/img/file/${getExtTypeByName(
                          item.displayName
                        )}.png`
                      "
                    >
                    </el-image>
                  </div>
                  <span class="text-ellipsis">{{ item.displayName }}</span>
                </span>
              </label>
            </div>
          </div>
        </div>
        <!--            <el-tabs tab-position="left" type="border-card" style="height: 570px;" class="pan-tabs">-->
        <!--                <el-tab-pane label="我的文件">-->
        <!--                </el-tab-pane>-->
        <!--                <el-tab-pane label="我的图片">-->
        <!--                </el-tab-pane>-->
        <!--            </el-tabs>-->
      </div>
      <div slot="footer" class="dialog-footer">
        <div class="flex pan-list-dialog--footer">
          <div class="flex-box-1">
            <el-button
              size="small"
              @click="panUploadVisible = !panUploadVisible"
              type="ghost"
              >上传附件
            </el-button>
          </div>
          <div class="flex-box-1">
            <el-pagination
              background
              layout="total, prev, pager, next"
              :current-page="listPage.page"
              :page-sizes="[10, 20, 30]"
              :page-size="listPage.pagesize"
              @size-change="pageSizeChange"
              @current-change="pageChange"
              :total="listPage.total"
            ></el-pagination>
          </div>
          <div class="flex-box-1">
            <el-button type="primary" @click="save">保存</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :visible="panUploadVisible"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      :append-to-body="true"
      :center="true"
      :show-close="false"
      width="640px"
    >
      <template slot="title">
        <div>上传{{ FILE_ACTIVE_TYPE[activeIndex] }}</div>
        <button class="el-dialog__headerbtn" @click="panUploadVisible = false">
          <i class="el-icon-close"></i>
        </button>
      </template>
      <div style="height: 370px;padding: 20px;">
        <ixu-upload
          class="upload-demo"
          :action="action"
          name="fileList"
          multiple
          :limit="20"
          :lrz="false"
          tip=""
          :fileList.sync="fileList"
          :show-file-list="true"
        >
          <el-button size="small" type="primary">上传附件 </el-button>
        </ixu-upload>
      </div>
      <div slot="footer" class="dialog-footer flex-main-center">
        <el-button type="primary" @click="saveSystemFile">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import page from "../../../src/mixins/page";
import get from "lodash/get";
import {
  FILE_MODULE,
  FILE_ACTIVE_TYPE,
  getExtType
} from "../../../src/utils/file";

export default {
  name: "ixu-pan",
  components: {},
  mixins: [page],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    fileType: {
      type: Array,
      default: function() {
        return ["img", "video", "file"];
      }
    },
    action: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      FILE_ACTIVE_TYPE,
      fileList: [],
      activeIndex: 0,
      loading: false,
      listPage: {
        total: 0,
        page: 1,
        pagesize: 18
      }, // 分页参数
      visibleSync: false,
      dialogVisibleLoading: false,
      checkFile: {},
      // 类型映射activeIndex
      indexToType: {
        file: 0,
        img: 3,
        video: 4
      },
      panUploadVisible: false,
      fileUploadList: []
    };
  },
  watch: {
    // 回调函数会将在观察(data observer)开始后立即被调用
    visible: {
      immediate: true,
      handler: function(val) {
        this.visibleSync = val;
        this.listData = [];
        if (!get(this.fileType, "length")) return;
        this.activeIndex = this.indexToType[this.fileType[0]];
        this.queryList();
      }
    },
    // 根据文件类型，修改activeIndex
    fileType: function(val) {
      if (!get(val, "length")) return;
      this.activeIndex = this.indexToType[val[0]];
      this.queryList();
    },
    activeIndex: function() {
      this.queryList();
    }
  },
  mounted() {},
  methods: {
    queryList() {
      if (this.$http) {
        this.loading = true;
        let param = {
          companyCode: this.$store.state.companyCode || undefined,
          COMPANYID:
            this.$store.state.adminInfo?.companyId ||
            this.$store.state.userInfo?.companyId ||
            undefined,
          page: this.listPage.page,
          pagesize: this.listPage.pagesize,
          fileType: this.activeIndex || undefined,
          fileModule: FILE_MODULE
        };
        this.$http
          .post("/bdsaas/ajax/oa/pageSystemFileByModule.do", param)
          .then(res => {
            let data = res.data?.data || res.data;
            console.log("pan::data", data);
            this.listData = data.result;
            this.listPage = data.pagination;
          })
          .catch(error => {
            console.log("error", error);
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.listData = [
          {
            cfsId: 3063,
            companyId: 242,
            profileId: 0,
            path: "/71800135BC15415EB84AAF83883BEE56/客户官网/服务中心.png",
            parentId: 0,
            isDirectory: false,
            displayName: "服务中心.png",
            fileTypeCd: "3",
            fileSize: 17875787,
            fileId: "",
            systemPropertyCd: "1",
            createdBy: 8,
            createdTime: 1609831197000,
            updatedBy: 8,
            updatedTime: 1609831197000,
            deleted: 0,
            fileUrl: ""
          }
        ];
        this.listPage = {
          pagesize: 18,
          page: 1,
          total: 10
        };
      }
    },
    getExtTypeByName(name) {
      const { type } = getExtType(name);
      return type;
      // if (type === 'png') {
      //     return fileUrl;
      // }
      // if (type !== 'none') {
      //     return `https://vip.bdsaas.com/static/img/file/${type}.png`;
      // }
      // return 'https://vip.bdsaas.com/static/img/file/png.png';
    },
    save() {
      this.$emit("save", this.checkFile);
      this.close();
    },
    close() {
      // this.$store.commit('createEditor/updatePanVisible', false)
      this.$emit("update:visible", false);
      // this.$emit('close', false)
    },
    fileUploadSuccess(file) {
      console.log("pan::fileUploadSuccess", file);
      if (!file) return;
      this.addSystemFile([file]);
    },
    // fileListUploadSuccess(fileList) {
    //     fileList = _.cloneDeep(fileList);
    //     console.log('pan::fileListChange1', fileList);
    //     fileList = fileList.filter(m => m.status === 'success');
    //     this.fileList = fileList.filter(m => m.status !== 'success');
    //     console.log('pan::fileListChange2', fileList);
    //     if (!(fileList && fileList.length > 0)) return;
    //     this.addSystemFile(fileList);
    // },
    // addSystemFile: _.throttle(function (fileList) {
    addSystemFile(fileList) {
      if (this.$http) {
        let param = {
          companyCode: this.$store.state.companyCode || undefined,
          COMPANYID:
            this.$store.state.adminInfo?.companyId ||
            this.$store.state.userInfo?.companyId ||
            undefined,
          fileIds: fileList.map(m => m.fileId).join(","),
          fileNames: fileList.map(m => m.name).join(","),
          fileSizes: fileList.map(m => m.size).join(","),
          fileModule: FILE_MODULE
        };
        this.$http
          .post("/bdsaas/ajax/oa/addSystemFile.do", param)
          .then(() => {
            this.fileList = [];
            this.queryList();
          })
          .catch(error => {
            console.log("error", error);
          })
          .finally(() => {});
      } else {
        this.fileList = [];
        this.queryList();
      }
    },
    saveSystemFile() {
      this.addSystemFile(this.fileList);
      this.panUploadVisible = false;
    }
  }
};
</script>

<style lang="scss">
.pan-list-dialog {
  /*.el-dialog__header {*/
  /*    display: none*/
  /*}*/

  .el-dialog__body {
    padding: 0px;
  }

  .el-dialog__footer {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    padding-left: 140px;
  }
}

$border: #d3d7e3;
.pan-tabs {
  box-shadow: none;
  border: none;
  border-top: 1px solid $border;

  .el-tabs__item {
    width: 139px;
    height: 64px;
    line-height: 64px;
    text-align: center !important;
    border-left: 4px solid transparent !important;
    border-bottom: 1px solid $border !important;
  }

  .el-tabs__item.is-active {
    border-left: 4px solid #378afe !important;
  }

  .el-tabs__header {
    background-color: #fff;
  }
}

.el-checkbox-pan {
  width: 107px;
  height: 130px;
  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 2px;
  position: relative;
  box-sizing: border-box;
  margin-right: 20px;
  margin-bottom: 30px;

  &.is-checked {
    border-color: #45a0fe;
  }

  .el-checkbox__inner {
    width: 17px;
    height: 17px;
  }

  .el-checkbox__inner::after {
    left: 6px;
  }

  .el-checkbox__input {
    position: absolute;
    right: 0;
    top: 0;
  }

  .el-checkbox__label {
    width: 100%;
    padding: 0;
    height: 100%;
  }

  .el-checkbox__image {
    width: 90px;
    height: 90px;
    margin: 9px auto;
    background: #f2f4fb;
    border-radius: 2px;
  }

  .text-ellipsis {
    font-size: 12px;
    text-align: center;
    color: #1f2d3d;
    width: 90px;
    margin: 0 auto;
  }
}

.pan-upload--btn {
  width: 79px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #3584fe;
  border-radius: 3px;
  color: #3584fe;
}

.el-upload--text.el-upload,
.el-upload--image.el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.el-upload--text.el-upload:focus,
.el-upload--text.el-upload:hover,
.el-upload--image.el-upload:focus,
.el-upload--image.el-upload:hover {
  border-color: #409eff;
}

.el-upload--text .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.el-upload--text .avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.el-upload--image {
  width: 260px;
  height: 130px;
  position: relative;
  border-radius: 0 !important;

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 260px;
    height: 130px;
    line-height: 130px;
    text-align: center;
  }

  .el-icon-sort {
    position: absolute;
    right: 0;
    bottom: 0;
    line-height: 30px;
    width: 30px;
    height: 30px;
    background: #409eff;
    color: #ffffff;
    transform: rotate(90deg);
  }
}

.el-upload--image.el-upload--image__square {
  width: 100px;
  height: 100px;

  .el-image {
    width: 100px;
    height: 100px;
  }

  .el-icon-plus.avatar-uploader-icon {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
}

.upload-demo {
  .el-upload--text.el-upload,
  .el-upload--image.el-upload {
    border: 1px dashed transparent;
  }
}
</style>
