<template>
  <main class="ixu-main">
    <slot></slot>
  </main>
</template>

<script>
export default {
  name: "IxuMain",
  componentName: "IxuMain"
};
</script>
<style lang="scss">
.ixu-main {
  display: block;
  flex: 1;
  flex-basis: auto;
  overflow: auto;
  box-sizing: border-box;
  padding: 20px;
}
</style>
