<template>
  <el-pagination
    class="ixu-pagination"
    :pageCount="pageCount"
    :background="background"
    :pagerCount="pagerCount"
    :pageSizes="pageSizes"
    :prevText="prevText"
    :nextText="nextText"
    :disabled="disabled"
    :hideOnSinglePage="hideOnSinglePage"
    @size-change="pageSizeChange"
    @current-change="pageChange"
    :current-page="currentPage"
    :page-size="pageSize"
    :total="total"
    :layout="layout"
  >
  </el-pagination>
</template>

<script>
export default {
  name: "ixu-pagination",
  data() {
    return {
      page: {
        pageSize: this.pageSize,
        currentPage: this.currentPage
      },
      layout: ""
    };
  },
  props: {
    pageSize: {
      type: Number,
      default: 10
    },

    small: Boolean,

    total: Number,

    pageCount: Number,

    pagerCount: {
      type: Number,
      validator(value) {
        return (
          (value | 0) === value && value > 4 && value < 22 && value % 2 === 1
        );
      },
      default: 7
    },

    currentPage: {
      type: Number,
      default: 1
    },

    pageSizes: {
      type: Array,
      default() {
        return [10, 20, 30, 40, 50, 100];
      }
    },

    popperClass: String,

    prevText: String,

    nextText: String,

    background: {
      type: Boolean,
      default: true
    },

    disabled: Boolean,

    hideOnSinglePage: Boolean
  },
  created() {
    this.getLayout();
  },
  methods: {
    getLayout() {
      if (0 < this.total <= 30) {
        this.layout = "total, prev, pager, next";
      }
      if (this.total > 30) {
        this.layout = "total, sizes, prev, pager, next, jumper";
      }
    },
    pageSizeChange(pageSize) {
      // this.pageChange(1)
      // this.currentPage = 1;
      // this.page.pageSize = pageSize;
      // 触发父组件查询请求
      this.$emit("size-change", pageSize);
    },
    // currentPage 改变时会触发
    pageChange(page) {
      // this.page.currentPage = page;
      try {
        this.$emit("current-change", page);
      } catch (error) {
        console.log("pageChange", error);
      }
    }
  }
};
</script>

<style lang="scss">
.ixu-pagination .el-pagination__total {
  margin-left: 24px;
  margin-right: 16px;
}
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  margin: 0 4px;
  border: 1px solid #d7dde4;
  background-color: #fff;
  color: #657180;
  min-width: 32px;
  border-radius: 2px;
}
.el-pagination {
  white-space: nowrap;
  padding: 2px 5px;
  color: #303133;
  font-weight: 400;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #20a0ff;
  border: none;
  color: #fff;
}
.el-pager li {
  padding: 0 4px;
  font-size: 13px;
  min-width: 32px;
  height: 32px;
  line-height: 32px;
  box-sizing: border-box;
  text-align: center;
}
.el-input--mini .el-input__inner {
  height: 32px;
  line-height: 32px;
}
.el-pagination button,
.el-pagination span:not([class*="suffix"]) {
  display: inline-block;
  font-size: 13px;
  min-width: 32px;
  height: 32px;
  line-height: 32px;
  vertical-align: top;
  box-sizing: border-box;
}
.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  margin: 0 4px;
  border: 1px solid #d7dde4;
  background-color: #fff;
  color: #657180;
  min-width: 32px;
  border-radius: 2px;
}
.el-pagination.is-background .btn-next.disabled,
.el-pagination.is-background .btn-next:disabled,
.el-pagination.is-background .btn-prev.disabled,
.el-pagination.is-background .btn-prev:disabled,
.el-pagination.is-background .el-pager li.disabled {
  color: #c0c4cc;
  min-width: 32px;
  height: 32px;
}
.el-pagination__jump {
  margin-left: 12px;
  font-weight: 400;
  color: #606266;
}
.el-pagination__sizes {
  margin: 0 12px 0 0;
  font-weight: 400;
  color: #606266;
}
</style>
