<template>
  <i :class="'ixu-icon-' + name"></i>
</template>

<script>
export default {
  name: "IxuIcon",
  props: {
    name: String
  }
};
</script>

<style lang="scss"></style>
