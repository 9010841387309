<template>
  <div
    class="vue-contextmenu-listWrapper"
    :class="'vue-contextmenuName-' + contextMenuData.menuName"
  >
    <slot>
      <li
        v-for="item in contextMenuData.menulists"
        class="context-menu-list"
        :class="{
          'context-menu-list-disabled': item.disabled,
          'context-menu-list-selectable': item.selectable
        }"
        :key="item.btnName"
      >
        <button
          @click="fnHandler(item)"
          :disabled="item.disabled"
          v-if="!item.selectable"
        >
          <ixu-icon :type="item.icoName" size="16"></ixu-icon>
          <span>{{ item.btnName }}</span>
        </button>
      </li>
    </slot>
  </div>
</template>

<script>
// https://github.com/chiic/vue-contextmenu/blob/master/src/VueContextMenu.vue
export default {
  name: "IxuContextMenu",
  props: {
    contextMenuData: {
      type: Object,
      requred: false,
      default() {
        return {
          menuName: null,
          axios: {
            x: null,
            y: null
          },
          menulists: [
            {
              fnHandler: "adddata",
              icoName: "fa fa-home fa-fw",
              btnName: "New"
            },
            {
              fnHandler: "savedata",
              icoName: "fa fa-home fa-fw",
              btnName: "Save"
            }
          ]
        };
      }
    },
    transferIndex: {
      type: Number,
      default: 0
    }
  },
  watch: {
    "contextMenuData.axios"(val) {
      try {
        document.removeEventListener("mousedown", function() {}, false);
        let x = val.x;
        let y = val.y;
        let index = this.transferIndex;
        let menuName = "vue-contextmenuName-" + this.contextMenuData.menuName;
        let menu = document.getElementsByClassName(menuName)[index];
        console.log("menu", menu);
        menu.style.display = "block";
        menu.style.left = x + "px";
        menu.style.top = y + "px";

        // 判断menu距离浏览器可视窗口底部距离,以免距离底部太近的时候，会导致menu被遮挡
        var menuHeight = this.contextMenuData.menulists.length * 36; //不能用scrollHeight,获取到的menu是上一次的menu宽高
        var menuWidth = 150; //不能用scrollWidth,同理
        var distanceToBottm =
          document.body.clientHeight - menu.offsetTop - menuHeight;
        // 同理判断距离右侧距离
        var distanceToRight =
          document.body.clientWidth - menu.offsetLeft - menuWidth;
        if (distanceToBottm < menuHeight) {
          menu.style.top = y - menuHeight + "px";
        }
        if (distanceToRight < menuWidth) {
          menu.style.top = x - menuWidth + "px";
        }

        document.addEventListener(
          "mouseup",
          function(e) {
            // 解决mac电脑在鼠标右键后会执行mouseup事件
            if (e.button === 0) {
              menu.style.display = "none";
            }
          },
          false
        );
        // eslint-disable-next-line no-empty
      } catch (error) {}
    }
  },
  methods: {
    fnHandler(item) {
      console.log("fnHandler:" + item.fnHandler);
      this.$emit(item.fnHandler);
      let index = this.transferIndex;
      let menuName = "vue-contextmenuName-" + this.contextMenuData.menuName;
      let menu = document.getElementsByClassName(menuName)[index];
      menu.style.display = "none";
    }
  }
};
</script>

<style lang="scss">
.vue-contextmenu-listWrapper {
  /*border: 1px solid #f0f0f0;*/
  /*box-shadow: 0 4px 12px rgba(0, 0, 0, .1);*/
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  display: none;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  padding: 12px;
  border-radius: 4px;
  background: #ffffff;
  color: #2f2f2f;

  .context-menu-list {
    width: 150px;
    height: 36px;
    /*border-radius: 4px;*/
    text-decoration: none;
    list-style: none;

    button {
      background-color: white;
      cursor: pointer;
      width: 100%;
      height: 100%;
      display: block;
      outline: 0;
      border: 0;
      color: #2f2f2f;
      height: 36px;
      line-height: 36px;

      i {
        float: left;
        line-height: 36px;
        width: 36px;
      }

      span {
        float: left;
      }

      i {
        padding: 0 10px 0 10px;
        color: #409eff;
      }
    }
  }

  .context-menu-list-disabled button {
    color: #8c8c8c;

    i {
      color: #8c8c8c;
    }
  }

  .context-menu-list-selectable {
    padding: 0;
    margin: 5px 0;
    border-bottom: 1px solid #f0f0f0;
    height: 0;
  }

  .context-menu-list:not(.context-menu-list-disabled) button:hover {
    box-shadow: 0px 1px 3px rgba(34, 25, 25, 0.2);
    color: #ffffff;
    /*border-radius: 4px;*/
    background: -webkit-linear-gradient(bottom, #409eff, #409eff);
    /* Safari 5.1 - 6.0 */
    background: -o-linear-gradient(bottom, #409eff, #409eff);
    /* Opera 11.1 - 12.0 */
    background: -moz-linear-gradient(bottom, #409eff, #409eff);
    /* Firefox 3.6 - 15 */
    background: linear-gradient(to bottom, #409eff, #409eff);

    i {
      color: #fff;
    }
  }
}
</style>
