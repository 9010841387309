<template>
  <aside class="ixu-aside" :style="{ width }">
    <slot></slot>
  </aside>
</template>

<script>
export default {
  name: "IxuAside",

  componentName: "IxuAside",

  props: {
    //属性传值
    width: {
      type: String,
      default: "300px"
    }
  }
};
</script>
<style lang="scss">
.ixu-aside {
  overflow: auto;
  box-sizing: border-box;
  flex-shrink: 0;
}
</style>
