export const EDITOR_CONFIG_MENUS = [
  "undo",
  "redo",
  "head",
  "fontSize",
  "fontName",
  "strikeThrough",
  "indent",
  "lineHeight",
  "bold",
  "italic",
  "underline",
  "table",
  "code",
  "splitLine",
  "foreColor",
  "backColor",
  // 'link',
  "list",
  "justify",
  "quote",
  "emoticon"
  // 'image',
  // 'video',
];
export const EDITOR_CONFIG_COLORS = [
  "#ffffff",
  "#dedede",
  "#a9a9a9",
  "#4b4b4b",
  "#353535",
  "#212121",
  "#000000",

  "#fcc02e",
  "#ffeb3c",
  "#fff59c",

  "#f67c01",
  "#ffa727",
  "#ffcc80",

  "#e64a19",
  "#fe5722",
  "#ffab91",

  "#d81b43",
  "#eb4165",
  "#fb879e",

  "#9c28b1",
  "#aa47bc",
  "#cf93d9",

  "#5d35b0",
  "#673bb7",
  "#9ea8db",

  "#1f87e8",
  "#42a5f6",
  "#90caf8",

  "#008781",
  "#26a59a",
  "#b2dfdc",

  "#05a045",
  "#83c683",
  "#c8e6ca"
];
