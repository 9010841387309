<template>
  <header class="ixu-header" :style="{ height }">
    <slot></slot>
  </header>
</template>

<script>
export default {
  name: "IxuHeader",

  componentName: "IxuHeader",

  props: {
    //通过属性来控制 header 高度
    height: {
      type: String,
      default: "60px"
    }
  }
};
</script>
<style lang="scss">
.ixu-header {
  padding: 0 20px;
  box-sizing: border-box;
  flex-shrink: 0;
}
</style>
