<template>
  <el-input
    class="ixu-input-color-picker"
    :size="size"
    :placeholder="placeholder"
    v-model="value"
  >
    <ixu-color-picker
      slot="suffix"
      v-model="value"
      :size="size"
    ></ixu-color-picker>
  </el-input>
</template>

<script>
import Vue from "vue";
import { Input } from "element-ui";

Vue.use(Input);
export default {
  name: "ixu-input-color-picker",
  data() {
    return {};
  },
  props: {
    value: String,
    placeholder: {
      type: String,
      default: "请输入颜色"
    },
    size: String
  },
  methods: {}
};
</script>

<style lang="scss">
.ixu-input-color-picker .el-color-picker__trigger {
  border: none !important;
}
.ixu-input-color-picker .el-color-picker__color.is-alpha {
  border: none !important;
  border-radius: 4px;
  overflow: hidden;
}
.ixu-input-color-picker .el-input__suffix {
  right: 0;
}
</style>
