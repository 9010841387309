<template>
  <div class="wEditor" :id="wEditorId"></div>
</template>

<script>
import wangEditor from "wangeditor";
import {
  EDITOR_CONFIG_MENUS,
  EDITOR_CONFIG_COLORS
} from "../../constants/common";
export default {
  name: "IxuEditor",
  props: {
    editorData: String,
    menus: {
      type: Array,
      default: function() {
        return EDITOR_CONFIG_MENUS;
      }
    },
    colors: {
      type: Array,
      default: function() {
        return EDITOR_CONFIG_COLORS;
      }
    },
    lineHeights: {
      type: Array,
      default: function() {
        return ["0.5", "1", "1.15", "1.6", "2", "2.5", "3"];
      }
    }
  },
  data() {
    return {
      wEditorId: "wEditor_" + parseInt(Math.random() * 1e10),
      editor: null
    };
  },
  mounted() {
    this.$nextTick(() => {
      // eslint-disable-next-line new-cap
      const editor = new wangEditor(`#${this.wEditorId}`);
      // 配置 onchange 回调函数，将数据同步到 vue 中
      editor.config.onchange = newHtml => {
        // this.editorData = newHtml
        this.$emit("update:editorData", newHtml);
      };
      editor.config.height = 450;
      // 2021.1.21：刚哥要求修改字号配置
      editor.config.fontSizes = {
        "x-small": { name: "12px", value: "1" },
        small: { name: "14px", value: "2" },
        normal: { name: "16px", value: "3" },
        large: { name: "18px", value: "4" },
        "x-large": { name: "24px", value: "5" },
        "xx-large": { name: "32px", value: "6" },
        "xxx-large": { name: "48px", value: "7" }
      };
      editor.config.menus = this.menus;
      editor.config.colors = this.colors;
      editor.config.lineHeights = this.lineHeights;
      // 配置全屏功能按钮是否展示
      editor.config.showFullScreen = false;
      // 关闭粘贴样式的过滤
      editor.config.pasteFilterStyle = false;
      // 忽略粘贴内容中的图片
      editor.config.pasteIgnoreImg = true;
      // 隐藏插入网络图片的功能
      editor.config.showLinkImg = false;
      // 取消自动 focus
      editor.config.focus = false;
      // 创建编辑器
      editor.create();
      // 重新设置编辑器内容
      editor.txt.html(this.editorData);
      this.editor = editor;
    });
  },
  methods: {},
  beforeDestroy() {
    // 调用销毁 API 对当前编辑器实例进行销毁
    this.editor.destroy();
    this.editor = null;
  }
};
</script>

<style lang="scss">
font[size="1"] {
  font-size: 12px;
}
font[size="2"] {
  font-size: 14px;
}
</style>
