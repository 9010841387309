<template>
  <el-container class="ixu-h-full">
    <el-main
      :class="{ 'box-table': !pageSize }"
      style="padding: 0;height: 100%"
    >
      <ixu-none v-if="!pageSize" :tip="tip">
        <slot name="none"></slot>
      </ixu-none>
      <slot v-else> </slot>
    </el-main>
  </el-container>
</template>

<script>
import Vue from "vue";
import { Container, Main } from "element-ui";
Vue.use(Container);
Vue.use(Main);

export default {
  name: "ixu-page-template",
  props: {
    pageSize: null,
    tip: {
      type: String,
      default: "暂无数据"
    }
  },
  data() {
    return {};
  },
  watch: {},
  computed: {
    currentStep() {
      return this.data.length;
    }
  },
  mounted() {},
  methods: {}
};
</script>

<style lang="scss">
.box-table {
  width: 100%;
  height: 100%;
  display: table;
}

.box-table-cell {
  text-align: center;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  display: table-cell;
}
</style>
