<template>
  <footer class="ixu-footer" :style="{ height }">
    <slot></slot>
  </footer>
</template>

<script>
export default {
  name: "IxuFooter",

  componentName: "IxuFooter",

  props: {
    //属性传值 控制高度
    height: {
      type: String,
      default: "60px"
    }
  }
};
</script>
<style lang="scss">
.ixu-footer {
  padding: 0 20px;
  box-sizing: border-box;
  flex-shrink: 0;
}
</style>
