<template>
  <div :ref="wEditorId"></div>
</template>

<script>
import QRCode from "@keeex/qrcodejs-kx";

export default {
  name: "IxuQrcode",
  props: {
    text: {
      type: String,
      required: true
    },
    size: {
      type: Number,
      required: false,
      default: 256
    },
    color: {
      type: String,
      required: false,
      default: "#000"
    },
    bgColor: {
      type: String,
      required: false,
      default: "#FFF"
    },
    errorLevel: {
      type: String,
      validator: function(value) {
        return value === "L" || value === "M" || value === "Q" || value === "H";
      },
      required: false,
      default: "H"
    }
  },
  watch: {
    text: function() {
      this.clear();
      this.makeCode(this.text);
    }
  },
  data() {
    return {
      wEditorId: "wEditor_" + parseInt(Math.random() * 1e10),
      qrCode: {}
    };
  },
  mounted() {
    this.qrCode = new QRCode(this.$refs[this.wEditorId], {
      text: this.text,
      width: this.size,
      height: this.size,
      colorDark: this.color,
      colorLight: this.bgColor,
      correctLevel: QRCode.CorrectLevel[this.errorLevel]
    });
  },
  methods: {
    clear: function() {
      this.qrCode.clear();
    },
    makeCode: function(text) {
      this.qrCode.makeCode(text);
    }
  }
};
</script>
