import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import icon from "./icon";
import "./demo-styles/index.scss";
Vue.config.productionTip = false;

import "highlight.js/styles/color-brewer.css";

import VcSnippet from "./components/snippet";
Vue.component("vc-snippet", VcSnippet);

// import "../lib/ixu.css";
// import IxuUI from "../lib/ixu.umd";
import "../src/theme/index.scss";
import IxuUI from "../src/index";
Vue.use(IxuUI);

import "element-ui/lib/theme-chalk/index.css";
import ElementUI from "element-ui";
Vue.use(ElementUI);

Vue.prototype.$icon = icon; // Icon 列表页用

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
