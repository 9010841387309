/**
 * 分页配置mixins
 * add: 2019-03-29 16:05:46
 * author: Shire
 */

const Mixins = {
  data() {
    return {
      listPage: {
        total: 0,
        page: 1,
        pagesize: 10
      }, // 分页参数
      listData: [] // 列表数据
    };
  },
  computed: {
    listDataLength() {
      return this.listData && this.listData.length;
    }
  },
  // computed: {},
  // watch: {},
  // created() {},
  methods: {
    clearPage() {
      this.listData = [];
      this.listPage = {
        total: 0,
        page: 1,
        pagesize: 10
      };
    },
    setPageData(data) {
      this.listData = data.result || [];
      this.listPage = data.pagination || {
        total: 0,
        page: 1,
        pagesize: 10
      };
    },
    // 写入数据
    setListPageData(info) {
      this.listData = info.result || [];
      this.listPage = info.pagination || {};
    },
    // pageSize 改变时会触发
    pageSizeChange(pageSize) {
      this.listPage.page = 1;
      this.listPage.pagesize = pageSize;
      this.queryList();
    },
    // currentPage 改变时会触发
    pageChange(page) {
      this.listPage.page = page;
      try {
        this.queryList();
      } catch (error) {
        console.log("pageChange", error);
      }
    },
    // 搜索内容变更 调用
    searchChange() {
      try {
        this.pageChange(1);
      } catch (error) {
        console.log("searchChange", error);
      }
    },
    // 回到顶部
    goPageTop() {
      let iScroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      let start = iScroll; //开始的滚动距离
      let end = 0; //结束的滚动距离
      let total = end - start; //移动的距离差
      let time = 500; //移动的总时长
      let count = Math.ceil(time / 30); //定时器走500毫秒需要走几步的总步数
      let n = 0; //初始化n.开启定时器后让 n++
      let timer = null; //定义定时器
      timer = setInterval(function() {
        n++;
        if (n == count) {
          clearInterval(timer);
        }
        if (document.documentElement.scrollTop) {
          document.documentElement.scrollTop = start + (n * total) / count;
          // total/count意思是每30秒，走一步移动的距离
        } else {
          document.body.scrollTop = start + (n * total) / count;
        }
      }, 30);
    }
  }
};

export default Mixins;
