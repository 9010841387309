<template>
  <ul>
    <draggable draggable=".draggable-item" @end="onEnd" filter=".disabled">
      <li
        v-for="(item, index) in list"
        :key="item[propKey] || index"
        :class="['draggable-item', direction, { disabled: !!item.disabled }]"
      >
        <slot name="item" v-bind:item="item">{{ item[prop] }}</slot>
      </li>
    </draggable>
  </ul>
</template>

<script>
import draggable from "vuedraggable";
export default {
  name: "IxuDraggable",
  components: {
    draggable
  },
  data() {
    return {};
  },
  props: {
    list: {
      type: Array,
      required: true
    },
    propKey: {
      type: String,
      required: true
    },
    prop: {
      type: String,
      required: false
    },
    direction: {
      type: String,
      validator: function(value) {
        return ["horizontal", "vertical"].indexOf(value) !== -1;
      },
      default: "horizontal"
    }
  },
  methods: {
    onEnd(evt) {
      const oldIndex = evt.oldIndex;
      const newIndex = evt.newIndex;
      const direction =
        oldIndex - newIndex === 0
          ? "-"
          : oldIndex - newIndex > 0
          ? "up"
          : "down";
      const updateList = this.list.map((item, index) => {
        // 向下移动
        if (direction === "down" && oldIndex <= index && index <= newIndex) {
          if (index === newIndex) return this.list[oldIndex];
          return this.list[index + 1];
        }
        // 向上移动
        if (direction === "up" && newIndex <= index && index <= oldIndex) {
          if (index === newIndex) return this.list[oldIndex];
          return this.list[index - 1];
        }
        return item;
      });
      this.$emit("update:list", updateList);
      if (direction !== "-") {
        this.$emit("change", {
          currentValue: updateList[newIndex],
          direction,
          step: Math.abs(oldIndex - newIndex)
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.draggable-item {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background: #fff;
  border: 1px solid #dcdfe6;
  color: #606266;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  transition: 0.1s;
  font-weight: 500;
  padding: 12px 20px;
  font-size: 14px;
  border-radius: 4px;
}
.draggable-item.disabled {
  cursor: not-allowed;
}
.draggable-item + .draggable-item {
  margin-left: 10px;
}
.draggable-item.vertical {
  width: 100%;
}
.draggable-item.vertical + .draggable-item.vertical {
  margin-left: 0;
  margin-top: 10px;
}
// .draggable-item:hover {
//   color: #409eff;
//   border-color: #c6e2ff;
//   background-color: #ecf5ff;
// }
</style>
