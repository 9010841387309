// import isArray from "lodash/isArray";
/**
 * @description 返回这个样式的颜色值
 * @param {String} type 样式名称 [ primary | success | warning | danger | text ]
 */
const TYPE_COLOR = {
  primary: "#3488ff",
  success: "#19be6b",
  warning: "#ff9900",
  danger: "#ed4014",
  default: "#303133"
};

function typeColor(type = "primary") {
  let color = "";
  color = TYPE_COLOR[getDefaultType(type)];
  return color;
}

/**
 * 获取默认type
 * @param type
 * @returns {string}
 */
function getDefaultType(type = "primary") {
  if (TYPE_COLOR[type]) {
    return type;
  }
  return "primary";
}

class IxuLog {
  // constructor(title, type = "default") {
  //   if (isArray(title)) {
  //     this.colorful(title);
  //     return;
  //   }
  //   type = getDefaultType(type);
  //   this[type](title, type);
  // }

  static getInstance() {
    if (!IxuLog.instance) {
      IxuLog.instance = new IxuLog();
    }
    return IxuLog.instance;
  }

  /**
   * @description 打印一个 [ title | text ] 样式的信息
   * @param {String} title title text
   * @param {String} info info text
   * @param {String} type style
   */
  capsule(title = "", info = "", type = "primary") {
    let background = typeColor(type);
    // console.log(title, info, type);
    console.log(
      `%c ${title} %c ${info} %c`,
      "background:#35495E; padding: 1px; border-radius: 3px 0 0 3px; color: #fff;",
      `background:${background}; padding: 1px; border-radius: 0 3px 3px 0;  color: #fff;`,
      "background:transparent"
    );
  }

  /**
   * @description 打印彩色文字
   * @param {{text: String, type: string}[]} textArr title textArr
   * @param lastSpace
   */
  colorful(textArr, lastSpace = false) {
    console.log(
      `%c${textArr.map(t => t.text + (lastSpace ? " " : "") || "").join("%c")}`,
      ...textArr.map(t => `color: ${typeColor(t.type)};`)
    );
  }

  /**
   * @description 打印 default 样式的文字
   * @param arg
   * @param type
   */
  colorfulAdapter(arg, type) {
    this.colorful(
      arg.map(text => {
        return { text, type };
      }),
      true
    );
  }

  /**
   * @description 打印 default 样式的文字
   * @param arg
   */
  default(...arg) {
    this.colorfulAdapter(arg, "default");
  }

  /**
   * @description 打印 primary 样式的文字
   * @param arg
   */
  primary(...arg) {
    this.colorfulAdapter(arg, "primary");
  }

  /**
   * @description 打印 success 样式的文字
   * @param arg
   */
  success(...arg) {
    this.colorfulAdapter(arg, "success");
  }

  /**
   * @description 打印 warning 样式的文字
   * @param arg
   */
  warning(...arg) {
    this.colorfulAdapter(arg, "warning");
  }

  /**
   * @description 打印 danger 样式的文字
   * @param arg
   */
  danger(...arg) {
    this.colorfulAdapter(arg, "danger");
  }

  log(...arg) {
    this.default(...arg);
  }

  info(...arg) {
    this.primary(...arg);
  }

  warn(...arg) {
    this.warning(...arg);
  }

  error(...arg) {
    this.danger(...arg);
  }
}

export default IxuLog.getInstance();
